.controls-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;

  .title,
  .controls {
    padding: 0.75rem;
  }

  .title {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.6010154403558299) 25%,
      rgba(79, 75, 75, 0.5021358885351015) 55%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .controls {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.7010154403558299) 30%,
      rgba(79, 75, 75, 0.5021358885351015) 60%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  svg {
    fill: rgba(white, 0.7);
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    margin: 0 11px;

    &:hover {
      fill: white;
    }
  }

  .speed-control {
    color: rgba(white, 0.7);
    cursor: pointer;

    &:hover {
      color: white !important;
    }
  }
}
